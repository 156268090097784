import localConfig from './settings/appSettings.local.json';
import devConfig from './settings/appSettings.dev.json';
import testConfig from './settings/appSettings.test.json';
import featureConfig from './settings/appSettings.feature.json';
import zombieStagingConfig from './settings/appSettings.zombie';
import migrationConfig from './settings/appSettings.migration.json';


import liveConfig from './settings//appSettings.live.json';


var hostname = document.location.hostname;

let config = null;
if(hostname.indexOf('localhost', 0) > -1)
{
    config = localConfig;
}
else if(hostname.indexOf('support-dev', 0) > -1)
{
    config = devConfig;
}
else if(hostname.indexOf('support-test', 0) > -1)
{
    config = testConfig;
}
else if(hostname.indexOf('support-feature', 0) > -1)
{
    config = featureConfig;
}
else if(hostname.indexOf('support.wwplus-zombie.com', 0) > -1)
{
    config = zombieStagingConfig;
}
else if(hostname.indexOf('workwareplus.com', 0) > -1)
{
    config = liveConfig;
}
else if(hostname.indexOf('workwareplus-training.com', 0) > -1)
{
    config = migrationConfig;
}

export const apiUrl = config.settings.apiUrl;
export const thisLocation = config.settings.thisLocation;
export const azureAdTenantId = config.settings.azureAd.tenantId;
export const azureAdClientId = config.settings.azureAd.clientId;
export const translationExpiration = config.settings.translationExpiration;